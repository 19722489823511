<template>
  <b-card-code>
    <!-- <p>{{organization}}organisation</p> -->
    <b-button
      v-if="this.$store.state.app.user.permissions.includes('user.add_user')"
      @click="addUser()"
      variant="primary"
      type="button"
      class="my-1"
      size="sm"
    >
      Add New
    </b-button>
    <b-card-code class="bg-light-secondary mb-1" title="Filters">
      <b-form inline style="display: inline-block; width: 100%">
        <div class="d-flex flex-row align-items-center justify-content-around">
          <div v-if="is_staff" class="w-100 d-flex">
            <v-select
              v-model="organization"
              label="name"
              placeholder="--Select Organization--"
              :options="organizations"
              @input="searchFn"
              autocomplete
              style="width: 30%"
              :reduce="(organization) => organization.tenant_uid"
            />
            <b-form-input
              class="ml-1"
              id="name"
              v-model="name_filter"
              style="width: 25%"
              name="name"
              placeholder="Search with name"
            />
            <v-select
              v-model="group_filter"
              label="text"
              placeholder="--Select Role--"
              :options="assetGroups"
              @input="searchFn"
              class="ml-1"
              autocomplete
              style="width: 25%"
              :reduce="(group_filter) => group_filter.value"
            />
            <b-button class="ml-1" variant="primary" @click="searchFn">
              <span v-if="isloading"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else>
                <feather-icon icon="SearchIcon" class="mr-50" />Search</span
              >
            </b-button>
          </div>
          <div v-else class="w-100 d-flex">
            <b-form-input
              class="ml-1"
              id="name"
              v-model="name_filter"
              style="width: 50%"
              name="name"
              placeholder="Search with name"
            />
            <v-select
              v-model="group_filter"
              label="text"
              placeholder="--Select Role--"
              :options="org_roles"
              @input="searchFn"
              class="ml-1"
              autocomplete
              style="width: 30%"
              :reduce="(group_filter) => group_filter.value"
            />
            <b-button class="ml-1" variant="primary" @click="searchFn">
              <span v-if="isloading"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else
                ><feather-icon icon="SearchIcon" class="mr-50" />Search</span
              >
            </b-button>
          </div>

          <!-- size="sm"  -->
        </div>
      </b-form>
    </b-card-code>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <!-- {{items}} -->
    <b-table
      responsive
      id="orgTable"
      :fields="filteredFields"
      :items="items"
      :per-page="perPage"
      ref="table"
      v-if="!loading"
      hover
      style="min-height: 200px"
    >
      <template v-slot:head(status)>
        <div
          class="d-flex justify-content-between"
          style="white-space: nowrap; overflow: hidden"
        >
          <span style="text-overflow: ellipsis">Is Active</span>
        </div>
      </template>
      <template v-slot:head(staff)>
        <div
          class="d-flex justify-content-between"
          style="white-space: nowrap; overflow: hidden"
        >
          <span style="text-overflow: ellipsis">Is Staff</span>
        </div>
      </template>
      <template v-slot:head(assigned_orgs)>
        <div
          class="d-flex justify-content-between"
          style="white-space: nowrap; overflow: hidden"
        >
          <span style="text-overflow: ellipsis">Assigned Organization</span>
        </div>
      </template>
      <template #cell(full_name)="data">
        <div
          class="d-flex justify-content-between"
          style="white-space: nowrap; overflow: hidden"
        >
          <span style="text-overflow: ellipsis" class="text-capitalize">{{
            data.item.full_name
          }}</span>
        </div>
      </template>
      <template #cell(index)="data">
        {{ data.index + 1 + perPage * (currentPage - 1) }}
      </template>
      <template #cell(first_name)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            variant="primary"
            size="sm"
            :text="data.item.first_name.charAt(0)"
          />
          <span class="d-flex" style="margin-left: 5px">{{
            data.item.first_name
          }}</span>
        </div>
      </template>

      <template #cell(roles)="data">
        <b-badge
          variant="primary"
          v-for="item in data.item.current_groups"
          :key="item.id"
        >
          {{ item.name }}
        </b-badge>
      </template>
      <template #cell(assigned_orgs)="data">
        <b-badge
          variant="info"
          v-for="item in data.item.assigned_orgs"
          :key="item.id"
        >
          {{ item.org_name }}
        </b-badge>
      </template>

      <!-- <template #cell(status)="data">
        <b-badge variant="success" v-if="data.item.is_active">Yes</b-badge>
        <b-badge variant="danger" v-else>No</b-badge>
      </template> -->

      <template #cell(staff)="data">
        <b-badge variant="success" v-if="data.item.is_staff">Yes</b-badge>
        <b-badge variant="danger" v-else>No</b-badge>
      </template>

      <template #cell(actions)="data">
        <b-dropdown
          size="sm"
          class="ml-1"
          variant="outline-primary"
          text="Actions"
        >
          <b-dropdown-item @click="invitationUser(data.item.id)"
            >Send Invitation</b-dropdown-item
          >
          <b-dropdown-item @click="editUser(data.item.id)"
            >Edit</b-dropdown-item
          >
          <b-dropdown-divider />
          <b-dropdown-item
            @click="
              gotoDelete(
                data.item.id,
                data.item.full_name,
                data.item.asset_count,
                data.item.task_count,
                data.item.is_staff,
                data.item.profile.organization
              )
            "
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>
    <b-pagination-nav
      v-model="currentPage"
      :numberOfPages="total"
      :total-rows="rows"
      :link-gen="linkGen"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="orgTable"
      use-router
      v-if="!loading"
    />
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      @close="reset()"
      size="lg"
    >
      <b-card-text>
        Delete user "
        <span class="text-primary text-capitalize">{{ userEmail }}</span
        >" ?
        <span class="text-primary text-capitalize">{{ userEmail }}</span> has
        <span class="text-warning">{{ task_count }}</span> Tasks,
        <span class="text-warning">{{ asset_count }}</span> Assets
      </b-card-text>
      <div v-if="!this.task_count == 0 || !this.asset_count == 0">
        <div class="">
          <b-form-radio-group
            v-model="selected"
            :options="options"
            class=""
            @change="selectUserDelete()"
          />
          <!-- <b-form-radio v-model="selected" name="some-radios" value="transfer">
            Transfer
          </b-form-radio>
          <b-form-radio
            v-model="selected"
            name="some-radios"
            value="nottransfer"
            @change="newd()"
          >
            I do not want to Transfer
          </b-form-radio> -->
        </div>
        <div
          v-if="selected == 'transfer'"
          class="border-primary p-1 my-1 rounded"
        >
          <div class="demo-inline-spacing">
            <b-form-checkbox v-model="is_reassign_task">
              Tasks
            </b-form-checkbox>
            <!-- {{is_reassign_task}} -->
            <b-form-checkbox v-model="is_change_ownership" v-if="!checkStaff">
              Assets
            </b-form-checkbox>
            <!-- {{is_change_ownership}} -->
          </div>
          <div class="my-1">
            <b-form-group label="To" label-for="user">
              <b-form-select
                class=""
                v-model="user"
                style="width: 100%"
                :options="filteredOptions"
              >
              </b-form-select>
              <!-- {{filteredOptions}} -->
              <!-- {{users}} -->
            </b-form-group>
            <b-button
              @click="transfer()"
              variant="primary"
              size="sm"
              class="mb-1"
              :disabled="!this.is_reassign_task"
            >
              Transfer
            </b-button>
            <div class="demo-spacing-0">
              <b-alert variant="warning" :show="isShow1">
                <div class="alert-body">
                  <span>Transfering in Progress.</span>
                </div>
              </b-alert>
              <b-alert variant="success" :show="isShow2">
                <div class="alert-body">
                  <span>Transfering is Done</span>
                </div>
              </b-alert>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <b-button
          v-if="this.task_count == 0 && this.asset_count == 0"
          @click="deleteUser()"
          variant="danger"
          size="sm"
        >
          Delete
        </b-button>
        <b-button
          v-else
          @click="deleteUser()"
          variant="danger"
          size="sm"
          :disabled="disableBtn"
        >
          Delete
        </b-button>
      </div>
    </b-modal>
    <b-modal
      id="modal-warning"
      hide-footer
      modal-class="modal-warning"
      centered
      title="Confirm.. !"
      v-model="openConfirmModal"
      size="lg"
    >
      <b-card-text>
        Are you sure you want to proceed without transfer?
      </b-card-text>

      <div class="d-flex justify-content-end">
        <b-button @click="cancelConfirm()" variant="secondary" size="sm">
          No
        </b-button>
        <b-button
          @click="deleteAfterModal()"
          variant="danger"
          size="sm"
          class="ml-1"
        >
          Yes
        </b-button>
      </div>
    </b-modal>

    <!-- <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="orgTable"
    /> -->
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckbox,
  BFormGroup,
  BAlert,
  BCardText,
  BAvatar,
} from "bootstrap-vue";
import FeatherIcon from "../../@core/components/feather-icon/FeatherIcon.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BFormSelect,
    BSpinner,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    BFormGroup,
    BAlert,
    vSelect,
    FeatherIcon,
    BCardText,
    BAvatar,
  },
  data() {
    return {
      pagination_pos: "center",
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      // organizations: this.$store.state.app.workspaces,
      organizations: [],
      name_filter: "",
      items: [],
      currentPage: 1,
      perPage: 10,
      is_search_on: false,
      loading: false,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#" },
        // { key: "name", label: "Name" , },
        { key: "full_name", label: "Full Name" },
        { key: "assigned_orgs", label: "Assigned Organization" },
        { key: "profile.o_profile[0].org_name", label: "Organization" },
        { key: "roles", label: "Roles" },
        // { key: "status", label: "Is Active" },
        { key: "staff", label: "Is Staff" },
        { key: "actions", label: "Actions" },
      ],
      userEmail: "",
      user_id: "",
      task_count: "",
      asset_count: "",
      openDeleteModal: false,
      openConfirmModal: false,

      options: [
        { text: "Transfer", value: "transfer" },
        { text: "I do not want to Transfer", value: "nottransfer" },
      ],
      selected: "",
      is_reassign_task: false,
      is_change_ownership: false,
      user: "",
      users: [],
      isShow1: false,
      isShow2: false,
      disableBtn: true,
      checkStaff: "",
      isloading: false,
      userOrg: null,
      group_filter: null,
      assetGroups: [{ value: null, text: "--Select Role--" }],
      org_roles: [{ value: null, text: "--Select Role--" }],
    };
  },
  computed: {
    filteredOptions() {
      return this.users.filter((user) => user.value !== this.user_id);
    },
    filteredFields() {
      if (!this.$store.state.app.user.is_staff) {
        return this.fields.filter(
          (field) =>
            field.key !== "assigned_orgs" &&
            field.key !== "profile.o_profile[0].org_name" &&
            field.key !== "staff"
        );
      } else {
        return this.fields;
      }
    },
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
  },
  created: function () {
    this.load();
    this.loadOrg();
    this.loadOrgGroup();
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    reset() {
      this.userEmail = "";
      this.user_id = "";
      this.task_count = "";
      this.asset_count = "";
      this.selected = "";
      this.is_reassign_task = false;
      this.is_change_ownership = false;
      this.user = "";
      this.isShow1 = false;
      this.isShow2 = false;
      this.disableBtn = true;
      this.checkStaff = "";
      this.load();
    },
    loadOrg: function () {
      if (this.is_staff) {
        // if (this.$store.state.app.workspaces.length == 1) {
        this.organizations = this.$store.state.app.workspaces.filter(
          (org) => org.tenant_uid === this.organization
        );
        this.organizations.push({
          name: "Secusy System",
          tenant_uid: "0",
          enabled_modules: [],
        });
        // this.organizations = this.$store.state.app.workspaces;
        // }
      } else {
        this.organization = this.$store.state.app.org_id;
        // this.organizations = this.$store.state.app.workspaces.filter(org => org.tenant_uid === this.organization);
        this.searchFn();
      }
    },
    load: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      let url = process.env.VUE_APP_BASEURL + "user/users";
      // if (this.organization != null) {
      //   url = url + "?q_org_id=" + this.organization;
      // }
      if (this.organization === "0") {
        url = url + "?role_type=0";
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data.results, "user list");
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
      });
    },
    loadOrgGroup: function () {
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "user/groups/?org_id=" +
          this.organization,
      };
      var self = this;
      this.$http(o_options).then((res) => {
        self.assetGroups = [];
        res.data.map(function (value, key) {
          let g = {
            value: res.data[key].id,
            text: res.data[key].name,
          };
          self.assetGroups.push(g);
          // if (res.data[key].role_type == 0) self.assetGroups.push(g);
          if (res.data[key].role_type == 1) self.org_roles.push(g);
        });
      });
    },
    loadOrgUsers: function () {
      this.users = [{ value: null, text: "--Select User--" }];
      this.loading = true;
      // Load User drop down
      let url =
        process.env.VUE_APP_BASEURL + "user/users/get-all-users/?query=true";
      if (this.is_staff && this.organization != "0") {
        url = url + "&q_org_id=" + this.organization;
      }
      if (this.organization === "0") {
        url = url + "&role_type=0&org_manager=1";
      }
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;

      this.$http(o_options).then((res) => {
        res.data.map(function (value, key) {
          let user = {
            value: res.data[key].id,
            text: res.data[key].first_name,
          };
          self.users.push(user);
        });
        this.loading = false;
      });
    },
    selectUserDelete() {
      if (this.selected == "nottransfer") {
        this.disableBtn = false;
      } else {
        this.disableBtn = true;
      }
    },
    searchFn: function (reset = true) {
      // this.is_search_on = false;
      this.isloading = true;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "user/users?query=true" +
        "&page=" +
        this.currentPage;
      // let data = {
      //     name : this.name_filter,
      //     severity : this.severity_filter,
      //     asset_id: this.asset_filter,
      //     status: this.status_filter
      // }
      if (this.organization == "0") {
        url = url + "&role_type=0";
      }
      if (this.organization != null && this.organization === 0) {
        this.filter.push({ organization: this.organization });
        url = url;
        // url = url + "&q_org_id=" + this.organization;
      }
      if (this.name_filter != null && this.name_filter != "") {
        this.filter.push({ name: this.name_filter });
        url = url + "&name=" + this.name_filter;
      }
      if (this.group_filter != null && this.group_filter != "") {
        this.filter.push({ group_filter: this.group_filter });
        url = url + "&group_id=" + this.group_filter;
      }
      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        // res.data.map(function (value, key) {
        //   // console.log(res.data[key].profile.organization)
        //   if (res.data[key].profile.organization != null) {
        //     res.data[key].profile.organization =
        //       res.data[key].profile.o_profile.org_name;
        //   }
        // });
        this.items = res.data.results;
        console.log(res.data.count, "counttttt");
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
        this.isloading = false;
      });
    },
    addUser() {
      this.$router.push({ name: "Add User" });
    },
    editUser(id) {
      if (this.organization === "0") {
        this.$router.push({
          name: "Edit User",
          params: { id: id },
          query: { is_staff: true },
        });
      } else {
        this.$router.push({ name: "Edit User", params: { id: id } });
      }
    },
    gotoDelete(id, full_name, asset_count, task_count, is_staff, organization) {
      this.openDeleteModal = true;
      this.user_id = id;
      this.userEmail = full_name;
      this.asset_count = asset_count;
      this.task_count = task_count;
      this.checkStaff = is_staff;
      this.userOrg = organization;
      this.loadOrgUsers();
    },
    deleteUser() {
      if (this.selected == "nottransfer") {
        this.openConfirmModal = true;
      } else {
        let url =
          process.env.VUE_APP_BASEURL + "user/users/" + this.user_id + "/";
        if (this.organization == "0") {
          url = url + "?role_type=0";
        }
        const options = {
          method: "DELETE",
          headers: { "content-type": "application/json" },
          url: url,
        };
        var self = this;
        this.$http(options)
          .then((res) => {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Deleted User Successfully",
                icon: "EditIcon",
                variant: "success",
              },
            });
            // if (res.error) {
            //   debugger
            //   self.$toast({
            //     component: ToastificationContent,
            //     props: {
            //       title: res,
            //       icon: "EditIcon",
            //       variant: "danger",
            //     },
            //   });
            // } else {
            //   self.$toast({
            //     component: ToastificationContent,
            //     props: {
            //       title: "Deleted User Successfully",
            //       icon: "EditIcon",
            //       variant: "success",
            //     },
            //   });
            // }
            console.log(res);
            this.openDeleteModal = false;
            this.load();
            //if(res.data.org_id){
            // this.$router.push({name: 'Organizations'});
            // }
          })
          .catch((err) => {
            console.log(err);
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "You cannot delete yourself.",
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    cancelConfirm() {
      this.openConfirmModal = false;
    },
    deleteAfterModal() {
      let url =
        process.env.VUE_APP_BASEURL + "user/users/" + this.user_id + "/";
      if (this.organization == "0") {
        url = url + "?role_type=0";
      }
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: url,
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Deleted User Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          console.log(res);
          this.openConfirmModal = false;
          this.openDeleteModal = false;
          this.load();
          //if(res.data.org_id){
          // this.$router.push({name: 'Organizations'});
          // }
        })
        .catch((err) => {
          console.log(err);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "You cannot delete yourself.",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    transfer() {
      this.isShow1 = true;
      let data;
      if (this.checkStaff) {
        data = {
          user: this.user_id,
          assignee: this.user,
          is_reassign_task: this.is_reassign_task,
        };
      } else {
        data = {
          user: this.user_id,
          assignee: this.user,
          is_reassign_task: this.is_reassign_task,
          is_change_ownership: this.is_change_ownership,
        };
      }
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "user/assign-task-ownership/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res.data, "user transfer");
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.isShow1 = false;
          this.isShow2 = true;
          this.disableBtn = false;
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    invitationUser(id) {
      // debugger;
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },

        url:
          process.env.VUE_APP_BASEURL +
          "user/users/" +
          id +
          "/send-invitation/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res.data, "message");
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>